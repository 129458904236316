.header{
    display: flex;
    justify-content: space-between;

}
.logo{
    width:10rem;
    height:3rem;
    box-shadow: 0 1px 2px black;
    padding:12px;
    border-radius: 10px;
    background-color: var(--darkGrey);

}

.header-menu{
    display: flex;
    list-style: none;
    gap:2rem;
    color:white;
  
}
.header-menu>li{
   transition: 0.2s all ease;
   cursor: pointer;
   font-size: 20px;
}
.header-menu>li:hover{
  color:var(--orange);

}
@media screen and (max-width:768px){
    .header>:nth-child(2){
        position: fixed;
        right:2rem;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        background:var(--appColor);
        padding:2rem
    }

    
}