
/* Hero section */
.hero{
    display: flex;
    justify-content: space-between;
    align-items: center;
   
}
.left-h{
    padding:2rem;
    padding-top:1.5rem;
    flex:3;
    display: flex;
    gap:2rem;
    flex-direction: column;
}
.the-best-ad{
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width:fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color:white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.the-best-ad>span{
    z-index: 2;
}
.the-best-ad>div{
    position: absolute;
   background-color: var(--orange);
    border-radius: 3rem;
    width:5.4rem;
    height: 80%;
    left:8px;
    z-index: 1;

}
/* Hero Heading */
.hero-text{
    display: flex;
    gap:1.5rem;
    flex-direction: column;
    text-transform: uppercase;
    font-size:4.5rem;
    font-weight: bold;
    color:white;
    text-transform: inherit;

}
.hero-text>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: capitalize;
    letter-spacing: 1px;
    width: 80%;

}

/* figures */
.figures{
    display: flex;
    gap:2rem;
}
.figures>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.figures>div>span:first-child{
    color:white;
    font-size: 2rem;
    
    
}
.figures>div>span:nth-child(2){
    color: var(--gray);
    font-size: 1.5rem;
    text-transform: capitalize;
}
/* hero btns */
.hero-buttons{
    display: flex;
    gap:1rem;
    font-weight: normal;

}

.hero-buttons>:first-child{
    color:white;
    background-color: var(--orange);
    width:8rem;
    

}
.hero-buttons>:nth-child(2){
    color:white;
    background-color: transparent;
    border:2px solid var(--orange);
    width:8rem;
    height: 3rem;
}

/* right hero section */
.right-h{
    flex:1;
    position: relative;
    min-height: 90vh;
   
    
    background-color:var(--orange);
}
.right-h>.btn{
    position:relative;
    top:2.5rem;
    left:1rem;
    color:black;
    background-color:rgb(72, 197, 72);
    border-radius: 10px;
    font-size: 20px;
    padding:10px;
    box-shadow: 0 1px 2px rgb(3, 11, 3);
}

.heart-rate{
    display: flex;
    flex-direction: column;
    gap:1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: start;
    border-radius: 5px;
    position: absolute;
    left:12rem;
    top: 9rem;
    justify-content: center;
    text-align: center;
    box-shadow: 0 1px 1px black;
   
}
.heart-rate>span:nth-child(2){
    color:var(--gray);
    
}
.heart-rate>span:nth-child(3){
    color:white;
    font-size: 1.5rem;
    
}
.heart-rate>img{
    width: 2rem;
}

/* hero images */
.hero_image{
    position: absolute;
    right:8rem;
    top:10rem;
    width:23rem;
}
.hero_image_back{
    position: absolute;
    top:4rem;
    right:20rem;
    z-index:-1;
    width:15rem;
}
.calories{
    display: flex;
    gap:2rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding:1rem;
    width:14rem;
    position: absolute;
    top:35rem;
    right:31rem;

}
.calories>img{
    width:3rem;
}
.calories>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.calories>div >:nth-child(1){
    color:var(--gray);
   
}
.calories>div >:nth-child(2){
    color:white;
    font-size: 1.5rem;
}
.hero-blur{
    width: 22rem;
    height: 30rem;
    left:0;
}

/* respnsive */

@media  screen and (max-width:768px) {
    .hero{
        flex-direction: column;
    }
    .hero-blur{
        width:14rem;
    }
    .the-best-ad{
        margin-top: 0;
        font-size: small;align-self: center;
        transform: scale(0.8);
    }
    .hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
       
    }
    .hero-text > div:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        align-self: center;
        text-align: center;
        display: none;
        
        
        

    }
    .hero-buttons{
        justify-content: center;

    }
    .figures{
        align-items: center;
        justify-content: center;
        text-align: center;
        
    }
    .figures>div >span:nth-of-type(1){
        font-size: large;
      

    }
    .figures>div >span:nth-of-type(2){
        font-size: small;
        align-self: center;
        
    }
    .right-h{
        position: relative;
        background: none;
        min-height: fit-content;
    }
    .btn-r-h{
        display: none;
    }
    .heart-rate{
        left: -2rem;
        top:2rem;
        width:6rem;

    }
    .calories{
        position: relative;
        top:5rem;
        left:-2rem;
    }
    .calories>img{
        width:2rem;
    }
    .calories>div>:nth-child(2){
        color:white;
        font-size: 1rem;
    }
    .hero_image{
        position: relative;
        width:15rem;
        left: 7rem;
        top:4rem;
        align-self: center;
    }
    .hero_image_back{
        width:13rem;
        left:4rem;
        top:0;
    }

}
