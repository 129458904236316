.footer-container{
    position: relative;
}
.footer-container>hr{
    border:1px solid var(--lightgray);

}
.Footer{
    padding:1rem 2rem;
    display: flex;
    flex-direction: column;
    gap:4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;

}
.social-links{
    display: flex;
    gap:4rem;
}
.social-links > img{
    width:2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f>img{
    width:10rem;
}
.footer-blur-1{
    bottom: 0;
    width:32rem;
    height: 23rem;
    right:15%;
    filter: blur(250px);
    background: rgba(153, 205, 50, 0.32);
   
}
.footer-blur-2{
    bottom: 0;
    width:32rem;
    height: 23rem;
    left:15%;
    filter: blur(250px);
   
}